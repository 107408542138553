import StringUtils from '@/StringUtils';

export default class ArrayUtils {
  static Move<T>(array: T[], from: number, to: number): T[] {
    const newArray = array.slice();
    newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);

    return newArray;
  }

  static ToObjectByProperty<T>(array: T[], property: keyof T): Record<string, T> {
    return array.reduce((acc, val) => {
      acc[val[property] as string] = val;
      return acc;
    }, {});
  }

  static Chunk<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }
}
